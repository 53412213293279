import { useState, useEffect } from "react"
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import axios from 'axios'
import './App.css'
import Contact from "./components/main/Contact"
import Dams from "./components/dams/Dams"
import Footer from "./components/main/Footer"
import Navigation from "./components/main/Navigation"
import Puppies from "./components/puppies/Puppies"
import Welcome from "./components/main/Welcome"

const App = () => {
  const [puppies, setPuppies] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const puppies = await axios.get("https://l29ww894s5.execute-api.us-east-2.amazonaws.com/Main/puppies", {params: {status: "Current"}})
      setPuppies(puppies.data)
    }
    
    fetchData()
  }, [])
  

  return (
    <Router>
        <Navigation/>
        <Routes>
          <Route path="/" element={<Welcome puppies={puppies}/>}/>
          <Route path="/puppies" element={<Puppies currentPuppies={puppies}/>}/>
          <Route path="/dams" element={<Dams/>}/>
          <Route path="/contact" element={<Contact/>}/>
        </Routes>
        <Footer/>
    </Router>
  )
}

export default App