import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import { Link } from "react-router-dom"

const Navigation = () => {
  return (
    <Navbar id="navbar" collapseOnSelect expand="md" variant="dark">
      <Navbar.Brand as={Link} to="/">Rock Creek Bulldog</Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navigation" />
      <Navbar.Collapse id="responsive-navigation">
        <Nav className="nav-link-right">
          <Nav.Link as={Link} to="/puppies" eventKey="1">Puppies</Nav.Link>
          <span className="separator"></span>
          <Nav.Link as={Link} to="/dams" eventKey="2">Dams</Nav.Link>
          <span className="separator"></span>
          <Nav.Link as={Link} to="/contact" eventKey="3">Contact</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default Navigation