const PuppyCards = ({ puppies }) => {
  return (
    <div className="container-fluid tan-bg">
      <div className="row row-cols-2 row-cols-lg-3 g-4">
      {puppies.map((puppy) => (
        <div className="col" key={puppy.id}>
        <div className="card">
        <div>
          <img
            src={require(`../../assets/pics/puppies/${puppy.name}.png`)}
            className="card-img-top card-img"
            alt={puppy.name}
          />
        </div>
          <div className="card-body">
            <h5 className="card-text">{puppy.name}</h5>
          </div>
        </div>
        </div>
      ))}
      </div>
    </div>
  )
}

export default PuppyCards