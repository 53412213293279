import { useState, useEffect } from 'react'
import axios from 'axios'
import PuppyCards from "../puppies/PuppyCards"
import SireAndDam from "../dams/SireAndDam"
import PuppiesNavigation from "./PuppiesNavigation"

import './Puppies.css'

const Puppies = ({ currPuppies }) => {
  const [page, setPage] = useState("Current")
  const [currentPuppies, setCurrentPuppies] = useState([currPuppies])
  const [previousPuppies, setPreviousPuppies] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const currPuppies = await axios.get("https://l29ww894s5.execute-api.us-east-2.amazonaws.com/Main/puppies", {params: {status: "Current"}})
      const puppies = await axios.get("https://l29ww894s5.execute-api.us-east-2.amazonaws.com/Main/puppies", {params: {status: "Previous"}})
      setCurrentPuppies(currPuppies.data.Items)
      setPreviousPuppies(puppies.data.Items)
    }

    fetchData()
  }, [])  

  if (previousPuppies.length === 0) {
    return "Loading..."
  }

  if (page === "Current") {
    if (currentPuppies.length >= 1) {
      return (
        <div className="bg-light-blue">
          <PuppiesNavigation page={page} setPage={setPage}/>
          <PuppyCards puppies={currentPuppies}/>
          <SireAndDam/>
        </div>
      )
    } else {
      return (
        <div>
          <PuppiesNavigation page={page} setPage={setPage}/>
          <h3 className="puppies-header bg-light-blue">No pups yet, but here are future litters to look out for!</h3>
          <SireAndDam />
        </div>
      )
    }
  } else {
    return (
      <div className="bg-light-blue">
        <PuppiesNavigation page={page} setPage={setPage}/>
        <PuppyCards puppies={previousPuppies} />
      </div>
    )
  }
}

export default Puppies