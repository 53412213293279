import { useEffect, useState } from 'react';
import axios from 'axios'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import './Dams.css'

const Dams = () => {
  const [dams, setDams] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const dams = await axios.get("https://l29ww894s5.execute-api.us-east-2.amazonaws.com/Main/dams")
      setDams(dams.data.Items);
    }
    
    fetchData()
  }, [])

  if (dams.length === 0) {
    return "Loading..."
  }

  if (dams.length >= 1) {
    return (
      <>
        {dams.map((dam, i) => (
          <div className="container-dam bg-lighter-blue" key={dam.id}>
            <Row className="row-dam">
              <Col xs={{order : (i % 2)}}>
                <Image
                  className="fluid-image-dam"
                  src={require(`../../assets/pics/dams/${dam.name}-Feature.png`)}
                  alt={dam.name}/>
              </Col>
              <Col className="name-dam">
                {dam.name}
              </Col>
            </Row>
          </div>
        ))} 
      </>
    )
  }
}

export default Dams