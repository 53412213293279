import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import './Contact.css'

const Contact = () => {
  return (
    <div className="contact-container">
      <div>
      <div className="contact-row">
          <FontAwesomeIcon icon={faEnvelope} transform="grow-50"/>
          <p>RockCreekBulldog@yahoo.com</p>
        </div>
      </div>
    </div>
  )
}

export default Contact