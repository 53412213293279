import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPaw} from '@fortawesome/free-solid-svg-icons'

const PuppiesNavigation = ({page, setPage}) => {
  if (page === "Current") {
    return (
      <div className="bg-light-blue">
        <div className="puppies-nav-container current">
          <div className="row">
            <div className="col-6">
              <h2 className="pup-nav-h2">Current</h2>
                <button className="btn btn-curr" onClick={() => setPage("Current")}>
                  <span className="fa-layers fa-fw">
                    <FontAwesomeIcon icon={faPaw} className="bg-paw"/>
                    <FontAwesomeIcon icon={faPaw}/>
                  </span>
                </button>
            </div>
            <div className="col-6">
              <h2 className="pup-nav-h2">Previous</h2>
              <button className="btn btn-prev" onClick={() => setPage("Previous")}>
              <span className="fa-layers fa-fw">
                    <FontAwesomeIcon icon={faPaw} className="bg-paw2"/>
                    <FontAwesomeIcon icon={faPaw}/>
                  </span>
                </button>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="bg-light-blue">
        <div className="puppies-nav-container current">
          <div className="row">
            <div className="col-6">
              <h2 className="pup-nav-h2">Current</h2>
                <button className="btn btn-curr" onClick={() => setPage("Current")}>
                  <span className="fa-layers fa-fw">
                    <FontAwesomeIcon icon={faPaw} className="bg-paw"/>
                    <FontAwesomeIcon icon={faPaw}/>
                  </span>
                </button>
            </div>
            <div className="col-6">
              <h2 className="pup-nav-h2">Previous</h2>
              <button className="btn btn-prev" onClick={() => setPage("Previous")}>
              <span className="fa-layers fa-fw">
                    <FontAwesomeIcon icon={faPaw} className="bg-paw2"/>
                    <FontAwesomeIcon icon={faPaw}/>
                  </span>
                </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}



export default PuppiesNavigation
