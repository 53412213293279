import React from 'react'

const Footer = () => {
  return (
    <footer>
      <div className="copyright">
        <small>Copyright &copy; 2022, Rock Creek Bulldog</small>
      </div>
    </footer> 
  )
}

export default Footer