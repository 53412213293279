import { useState, useEffect } from 'react'
import axios from 'axios'
import './SireAndDam.css'

const SireAndDam = ({ limit }) => {
  const [couples, setCouples] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const couples = await axios.get("https://l29ww894s5.execute-api.us-east-2.amazonaws.com/Main/dams")
      if (limit) {
        setCouples(couples.data.Items[3])
      } else {
        setCouples(couples.data.Items)
      }
    }
  
    fetchData()
  }, [])

  if (couples.length === 0) {
    return 'Loading...'
  }

  if (couples.length > 0 || couples != null) {
    return (
      <div className="bg-light-blue">
        {limit !== 1 ?
          couples.map((couple) => (
            (couple.sire &&
              <div className="sire-and-dam-container" key={couple.id}>
                <div className="sire-and-dam">
                  <h3 className="sire-and-dam-header">Litter Coming {couple.due}</h3>
                  <div className="sire-and-dam-flex">
                    <div className="sire-and-dam-dam">
                      <img
                        src={require(`../../assets/pics/sire-and-dam/${couple.name}.png`)}
                        alt={couple.name}
                        className="sire-and-dam-img"/>
                      <p className="name">{couple.name}</p>
                    </div>
                    <div className="sire-and-dam-sire">
                      <img
                        src={require(`../../assets/pics/sire-and-dam/${couple.sire}.png`)}
                        alt={couple.sire}
                        className="sire-and-dam-img"/>
                      <p className="name">{couple.sire}</p>
                    </div>
                  </div>
                </div>
              </div>
             )
          ))
          :
          <div className="sire-and-dam-container" key={couples.id}>
              <div className="sire-and-dam">
                <h3 className="sire-and-dam-header">Litter Coming {couples.due}</h3>
                <div className="sire-and-dam-flex">
                  <div className="sire-and-dam-dam">
                    <img
                      src={require(`../../assets/pics/sire-and-dam/${couples.name}.png`)}
                      alt={couples.name}
                      className="sire-and-dam-img"/>
                    <p className="name">{couples.name}</p>
                  </div>
                  <div className="sire-and-dam-sire">
                    <img
                      src={require(`../../assets/pics/sire-and-dam/${couples.sire}.png`)}
                      alt={couples.sire}
                      className="sire-and-dam-img"/>
                    <p className="name">{couples.sire}</p>
                  </div>
                </div>
              </div>
            </div>
        }
      </div>
    )
  }
}

export default SireAndDam
