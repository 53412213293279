import { faHeart } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {Link} from "react-router-dom"
import PuppyCards from "../puppies/PuppyCards"
import SireAndDam from "../dams/SireAndDam"

const Welcome = ({ puppies }) => {
  return (
    <div className="container-fluid">
      <div className="rcb-bg">
        <div className="immg"></div>
      </div>
      <div className="row align-items-center bg-lighter-blue main-container" id="title">
        <div className="col-lg-6">
          <p className="quote">
            “Dogs leave paw prints on our hearts.”
          </p>
          <FontAwesomeIcon icon={faHeart} transform="grow-50" />
        </div>
        <div className="col-lg-6">
          <img className="main-img" src={require("../../assets/pics/puppies/The Chosen One.png")} alt="Ms. Blue Eyes" />
        </div>
      </div>
      {puppies.length < 0 ?
        <div className="bg-light-blue">
          <Link className="text-btn btn-pups" to="/puppies">See All Puppies</Link>
          <PuppyCards puppies={puppies} />
        </div>
        :
        <div className="bg-light-blue">
          <Link className="text-btn btn-pups" to="/puppies">See All Upcoming Litters</Link>
          <SireAndDam limit={1}/>
        </div>
      }
      <div className="bg-lighter-blue last-div">
        <Link className="text-btn btn-dams" to="/dams">See All Dams</Link>
        <img className="dams-bg-img" src={require("../../assets/pics/Dams.png")} alt="Dams"/>
      </div>
    </div>
  )
}

export default Welcome